/*** Apply ***/

.hero--apply{
    @include leap-rev;
}


/*** Home ***/

%cover {
  overflow: hidden;
  height: 100%!important;
  width: 100%;
}

.hero--home {
  @include leap-rev;
  height: 100vh;
  min-height: 630px;
	position: relative;
  overflow: hidden;
  padding: 74px 0 20px;
}

.scroll-down {
	width: 100%;
	position: absolute;
	bottom: 20px;
	svg {
		margin: auto;
    width: 35px;
    display: block;
	}

}

/*** About ***/

.resources {
  @extend %t-c;
  align-items: baseline;

  >*{
    padding: 40px;
    img{
      margin: 20px auto;
    }
  }
}

.how{
  @extend %gutters-wide;
  align-items: center;
  &:nth-child(even){
    flex-direction: row-reverse;
  }
  >*{
    @extend %padded-2;
    img{
      margin: auto;
      max-height: 400px;
    }
  }
}

.page-about-the-challenge {
  .private-partners {
    justify-content: center;
  }
}

.stats_wrap{
  background-color: $peach;
  padding: 30px;
  @include s-m{
    padding: 50px;
  }
  h1,
  h4{
    color: $leap-green;
    margin: 0;
  }
  .unit{
    margin: 2em 0;
  }
}

.sdgs_wrap{
  @include med{
    width: 80%;
    margin: 0 auto;
  }
}

/*** CHallenge ***/

.venture-checklist {

  @include fontscale (18px, 20px, 24px);
  font-family: 'Gilroy';
  font-weight: 700;
  padding: 40px;
  background: $leap-green;
  color: #fff;
  margin: 0;
  li {
    list-style: none;
    position: relative;
    margin: 20px 0 20px 42px;
    line-height: 1.4em;

  }
  li:before{
    position: absolute;
    left: -53px;
    content: '';
    display: inline-block;
    height: 42px;
    width: 42px;
    background-image: url(../img/check.png);
    background-size: contain;
    background-repeat: no-repeat;
    top: 13px;
}

}

/*** Gutenberg ***/

.wp-block-button a,
.wp-block-button.is-style-fill a {
	@extend %btn-base;
	@include fontscale(20px, 22px, 24px);
 	letter-spacing: .025em;
	padding: 0 1em;
	line-height: 58px;
	font-weight: 300;
	text-decoration: none;
	display: inline-block;
	text-align: center;
	min-width: 6em;
	height: 60px;
	min-width: 240px;
}

.video-modal{
	width: 148px;
	margin: 30px auto 0;
	display: block;
	text-align: center;
	svg{
		width: 100px;
		display: block;
		margin: 0 auto 16px;
		@include transition;
	}
	&:hover{
		color: $amber;
		svg {
			transform: scale(1.1);
		}
	}
	&:active{
		svg {
			transform: scale(1);
		}

	}
}

.report-content {
  align-items: flex-end;
}

.report-partners {
  img {
    max-height: 40px
  }
}

.report-buttons {

  @include max-sm {
    justify-content: center;
    .btn {
      min-width: auto;
      margin: 10px;
    }

  }
}
