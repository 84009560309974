/*** Components ***/
// Modules that are reused or complex elements.

@mixin leap-rev {
  background: $leap-green;
  color: #fff;
  h1,
  h2,
  h3,
  h4,
  p {
    color: #fff;
  }
  a {
    color: #fff;
  }
}

.cta-primary{
  min-height: 500px;
  @include leap-rev;
  align-items: center;
  min-height: 630px;
	position: relative;
  overflow: hidden;
  padding: 74px 0 20px;
  @include s-m {
    height: 100vh;
  }
}



.hero{
  padding: 140px 0 20px;
}

.hero-bg {
  position: absolute;
  top: 0;
  left: 0;
  @extend %cover;
  img{
    @extend %cover;
    object-fit: cover;
    object-position: center center;
  }
}




/*** Sections ***/

.section-space{
  padding-top: 40px;

  @include med {
    padding-top: 100px;

  }

}

.section--light{
  background-color: #FFF1E8;
  padding-bottom: 40px;
  @include med {
    padding-bottom: 100px;
  }
}

.link-has-arrow a,
.link-arrow{
  display: inline-block;
  &::after{
    content: '';
    height: 1.3em;
    width: 36px;
    background: url('../img/link-arrow.svg') no-repeat bottom center;
    display: inline-block;
    @include transition;
  }
  &:hover{
    color: $jade;
    &::after{
      background: url('../img/link-arrow.svg') no-repeat bottom right;
    }
  }

}

.private-partners {
  align-items: baseline;
  margin: 0 -30px;
  .logo {
    margin: 30px;
    @include s-m {
      margin: 0 30px 30px;
    }
  }
  img {
    max-height: 46px;
    max-width: 200px;
  }
  &.challenge {
    img {
      max-height: none;
      @include s-m {
        max-height: 46px;
      }
    }
  }
}


.global-partners {
  .logo:first-child{
    img {
      max-height: 80px;
    }
  }
}

.logos--leap-logo {
  max-width: 420px;
  img {
    height: auto!important;
    display: block;
    width: 100%;
  }
}

.logos--phac-logo {
  img {
    max-width: 460px;
  }
}

.logos--bcg-logo {
  img {
    max-width: 180px;
  }
}



/*** 3 Targets ***/

.targets {
  align-items: baseline;
  .target {
    @extend %h-center;


  }
  img {
    max-height: 300px;
    max-width: 360px;
  }
}

/**** Content Area ***/

// .content {
//   // flex: 1 0 auto;
//   margin-top: 60px;
//   @include med {
//     margin-top: 80px;
//   }

//   &--news {
//     padding-top: 2em;
//     @include s-m {
//       padding-top: 4em;
//     }
//   }

//   &--single {
//     padding-top: 2em;
//     @include s-m {
//       padding-top: 4em;
//     }
//     figcaption {
//       text-align: center;
//       margin-top: .8em;
//       margin-bottom: 1em;

//       @include fontscale(12px, 14px, 16px);
//     }
//   }
//   &.results {
//     padding-bottom: 4em;
//   }
// }



/*** Learn ***/


/** Featured article ***/

.article-overlay {
  position: absolute;
}
.featured-blurb {
  @include gutter-pad-wide;
}


/*** Single ***/

.single-post {
  .site-header {
    color: #000;
    background: #fff;
    .brand-logo svg{
      fill: #000;
    }
  }
}

.catlink, .cat-link{
  color: $leap-green;
  font-weight: 700;
}
h1.article-title{
  margin: 10px 0;
}

.single-date{
  font-weight: 700;
  margin-bottom: 40px;
}

/*** Modal ***/

.modal-close {
  position: absolute;
  z-index: 1;
  top: 25px;
  right: 25px;
  width: 30px;
  height: 30px;
  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 15px;
    width: 2px;
    height: 30px;
    background-color: $leap-green;
    @include transition(all, 0.35s);
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
  &:hover {
    &::before,
    &::after {
      background-color: $leap-green;
    }
  }
}

/*** Fancybox ***/

// Fancybox
// .fancybox-bg {
//   background: $color-bg-ultra-dark-grey!important;
// }
//
// .fancybox-button {
//   background: transparent!important;
// }
//
// .fancybox-slide--html {
//   padding: 0!important;
// }

// .fancybox-button {
//   &--arrow_left,
//   &--arrow_right {
//     @include s-m {
//       padding: 0!important;
//     }
//   }
//   &--arrow_left {
//     @include s-m {
//       left: 25px!important;
//     }
//   }
//   &--arrow_right {
//     @include s-m {
//       right: 25px!important;
//     }
//   }
// }

.page-template-template-platform {
  .fancy-modal {
    width: 100%;
    height: 100%;
    background-color: transparent;
    padding: 60px;
    display: flex;
    align-items: center;
    @include med {
      padding: 0 175px;
    }
    .modal-video {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

/*** case Studies ***/

.single-case-card {
  display: block;
  background: $peach;
  justify-content: center;


  .case-head {
    padding: 40px;
  }
  .case-img {
    max-height: 400px;
  }
  img {
    @include transition;
  }
  h3 {
    color: $leap-green;
    @include fontscale(20px, 24px, 32px);
    font-weight: 700;
    text-decoration: underline;
	}
  &:hover {
    h3 {
      color: $dark-green;
    }
    img {
      transform: scale(1.1);
    }
  }

}

.img-wrap {
  overflow: hidden;
    height: 100%!important;
    width: 100%;
    img {
      overflow: hidden;
    height: 100%!important;
    width: 100%;
    object-fit: cover;
    object-position: center center;
    }
}

/*** Fancybox ***/

.fancybox-button {
  height: 66px;
  width: 66px;
}

/*** Process modal ***/

.process-link {

}

.process-box {
  display: block;
  text-align: center;
  border: 2px solid $leap-green;
  display: block;
  color: $leap-green;
  position: relative;
  // padding: 20px;
  padding-top: 50%;
  text-align: center;
  margin-bottom: 1em;
  @include med {
    padding-top: 90%;
  }
  &:hover {
    background: $mint;
  }

  h2 {
    // margin: 1em 0;
    // max-width: 100%!important;
    @include fontscale(30px, 30px, 48px);
    margin: 0;
    padding: 0 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%!important;
    width: 100%;
  }
  .process-number {
    position: absolute;
    top: -2px;
    left: -2px;
  }
}

.process-number {
  width: 44px;
  height: 44px;
  border: 2px solid $leap-green;
  display: block;
  color: $leap-green;
  text-align: center;
  line-height: 44px;
}

.process-detail {
  background: $mint;
  padding: 40px;
  max-width: 800px
}

.process-date {
  text-align: center;
  color: $leap-green;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: 'Gilroy';
  font-weight: 700;
  @include fontscale(14px, 16px, 18px);
  margin-bottom: 2em;

  span {
    padding: 8px 0;
    border-bottom: 5px solid $leap-green;
  }
}

.process-detail-header {
  align-items: center;
  .process-number {
    margin-right: 20px;
  }
  h2 {
    margin: 0;
  }
}
.page-about-the-challenge {
  .fancybox-content {
    background: $mint;
  }
}

/*** case study ***/

.case-hero {
  align-items: flex-end;
  padding-bottom: 40px;
  @include med {
    padding-bottom: 100px;
  }

  h4 {
    color: $orange!important;
  }
}

.case-stats {
  margin-top: 3em;
  font-family: 'Gilroy';
  color: #fff;
  background: $orange;
  max-height: 100vh;
  height: 100vh;
  align-items: center;
  @include flex;
  h2 {
    max-width: 100%;
    color: #fff;
    @include fontscale(30px, 60px, 90px);
  }
  h2.stat-number--fr {
    @include fontscale(30px, 30px, 36px);
  }


}



.wp-block-quote{
  position: relative;
  @include med {
    margin-left: -80px;
    margin-right: -80px;
  }
  p {
    font-family: 'Gilroy';
    @extend %h3;
    &::before {
      position: absolute;
      top: 0;
      left: -70px;
      content: '';
      display: block;
      height: 60px;
      width: 60px;
      background: url('../img/pre-quot.svg') no-repeat center;
    }

    &::after {
      position: absolute;
      bottom: 0;
      right: -70px;
      content: '';
      display: block;
      height: 60px;
      width: 60px;
      background: url('../img/post-quot.svg') no-repeat center;
    }
  }
  cite {
    font-style: normal;
  }
}
