/*** Type ***/

html {
	font-size: $main-font-size;
}

body {
	-webkit-font-smoothing: antialiased;
	font-family: $font;
	@include fontscale(16px, 18px, 20px);
	line-height: 1.5em;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5 {
	font-family: $font-display;
	font-weight: bold;
	line-height: 1em;
	margin: 0 0 0.3em;
}

h1, .h1 {
	@include fontscale(36px, 48px, 64px);
	color: $leap-green;
	margin: 0 0 0.3em;
	line-height: 1.2em;
}

h2, .h2 {
	@include fontscale(30px, 40px, 48px);
	line-height: 1.4em;
	color: $leap-green;
	@include med{
		max-width: 80%;
	}
}

h3, .h3, %h3 {
	@include fontscale(24px, 30px, 35px);
	color: $dark-green;
	font-weight: 400;
	line-height: 1.4em;
}

* + h2,
* + .h2 {
	margin-top: 0.5em;
}

h3 + h4 {
	margin-top: 50px
}

h4, .h4 {
	@include fontscale(18px, 22px, 28px);
	line-height: 1.5em;
	color: $dark-green;
	width: 100%;
	a {
		padding: 12px 0;
		color: $dark-green;
	}
}

.eyebrow,
.h4-brow h4 {
	color: #FF5B0F;
	letter-spacing: 1px;
	text-transform: uppercase;
	font-weight: 700;
	@include fontscale(14px, 18px, 22px);
	display: inline-block;
  width: auto;
	padding-bottom: 8px;
	border-bottom: 5px solid $orange;

}

h5 {
	@include fontscale(18px, 20px, 22px);
	line-height: 1.6em;
}

p {
	line-height: 1.5em;
	margin: 0 0 1em;
	&.large {
		@include fontscale(16px, 18px, 20px);
	}
	&.larger {
		@include fontscale(20px, 22px, 24px);
	}

	a {
		color: $leap-green;
		text-decoration: underline;
		font-weight: 700;
		&:hover {
			color: $jade;
		}
	}
}

.hero--home p {
	@include fontscale(16px, 18px, 20px);
}

p + p {
	margin-top: 1em;
}



// ul + h3,
// ol + h3,
// blockquote + h3 {
// 	margin-top: 1.5em;
// }

// h3 + h2,
// p + h2,
// ul + h2,
// ol + h2,
// blockquote + h2 {
// 	margin-top: 1.25em;
// }

// p + h3 {
// 	margin-top: 2em;
// }

// p + h4 {
// 	margin: 0.5 0 0.5em 0;
// }



a {
	color: $color-black;
	text-decoration: none;
	@include transition(all, 0.35s);
}

li {
	line-height: 1.8em;
}

.underlined,
%underlined {
	width: fit-content;
	&::after {
		content: "";
		width: 100%;
		display: block;
		background-color: #4a4a4a;
		height: .08em;
		margin-top: 0.3em;
		margin-bottom: 1.25em;
	}
}

.underline {
	position: relative;
  display: inline;
	&::after {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		bottom: -0.125em;
		width: 100%;
		border-bottom: 1px solid $color-black;
		@include med {
			border-bottom: 2px solid $color-black;
		}
	}
}

.em {
	font-style: italic;
}

.mobile-content--left {
	> * {
		@include max-med {
			text-align: left!important;
		}
	}
}
