
@font-face{
    font-family:"Gilroy";
    font-style: normal;
    font-weight: bold;
    src:url("../../fonts/5538959/e62d07e6-5c21-45b8-8427-79c95511907d.woff2") format("woff2"),url("../../fonts/5538959/e667d70b-2326-4c62-8f38-200c31c3b08c.woff") format("woff");
}
@font-face{
    font-family:"Gilroy";
    font-style: normal;
    font-weight: normal;
    src:url("../../fonts/5538985/94b9ba0e-1690-4966-8af8-7d8a1c22c221.woff2") format("woff2"),url("../../fonts/5538985/fd4654af-3e2f-4601-bb81-d6b08f68d9f0.woff") format("woff");
}


@import 'normalize';
@import 'variables';
@import 'mixins__layout';
@import 'mixins__style';
@import 'base';
@import 'global';
@import 'icons';
@import 'layout';
@import 'components';
@import 'menu';
@import 'helpers';
@import 'type';
@import 'forms';
@import 'theme';
@import 'grid';
@import 'interaction';
@import 'flickity';
