.no-scroll {
  overflow: hidden;
  height: 100%;
}

.no-touch {
  .nav a:hover {
    color: $leap-green;
    &:before {
      width: 100%;
      opacity: 1;
    }
  }
}

.padded,
%padded {
  padding: 30px;
}

.padded-2,
%padded-2 {
  padding: 60px;
}

.bodylink,
%bodylink {
  color: $color-body;
  &:hover {
    color: $leap-green;
  }
}

.sr-only,
.icon-label {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}.has-graphics {
  overflow: hidden;
}

//make stuff the height of the screen
.sh,
%sh {
	height: 100vh;
	min-height: 400px;
}

.media-embed {
  @include med {
    iframe {
      height: 100vh;
      width: 100%;
    }
  }
}

.non-list,
%non-list {
  list-style: none;
  padding: 0;
  margin: 0 0 1em;
}


.t-c,
%t-c {
  text-align: center;
}

.s-m-t-c {
  @include s-m {
    text-align: center;
  }
}

.t-r {
  text-align: right;
}

.o-h {
  overflow: hidden;
}

.dis-ib {
  display: inline-block;
}

.dis-n {
  display: none;
}

.bg-w {
  background-color: $color-accent-contrast;
}


.divide-t {
  border-top: 1px solid $color-grey;
}

.color-is-inverted {
  background-color: $color-black;
  * {
    color: $color-accent-contrast;
  }
}

// .content {
//   flex: 1 0 auto;
// }
