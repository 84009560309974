/*** Forms ***/

%btn-base{
	@include transition;
	text-decoration: none;
	text-align: center;
	text-transform: uppercase;
	display: inline-block;
	font-weight: 700;
	color: #fff;
	border: 2px solid #F54E00;
	background: #F54E00;
	&:hover,
	&:focus {
		background: #fff;
		color: $orange;
	}
}

.btn,
%btn {
	@extend %btn-base;
	@include fontscale(20px, 22px, 24px);
	text-decoration: none;
 	letter-spacing: .025em;
	padding: 0 1em;
	line-height: 58px;
	height: 60px;
	min-width: 240px;

	&:active{
		transform: scale(0.9);
	}

}

.btn--2nd {
	background: transparent;
	color: $orange;

	&:hover {
		color: #fff;
		background: $orange;
	}

}

.btn--3rd {
	background: transparent;
	color: #fff;
	 border-color: #fff;

	&:hover {
		color: #fff;

	}

}

.btn--med{
	min-width: 100px;
	height: 44px;
	line-height: 44px;
}


%btn-clear {
	background: transparent;
	border-color: transparent;
	&:hover,
	&:focus {
		background: transparent;
		color: $leap-green;
	}
	&:active {
		transform: none;
		background: transparent;
	}
}

%btn-sm {
	font-size: 0.8em;
}

///



.btn--clear,
.btn-group--clear .btn {
	@extend %btn-clear;
}

.btn--sm,
.btn-group--sm .btn {
	@extend %btn-sm;
}

input {
	box-sizing: border-box;
	font-size: 24px;
}

input[type="text"],
input[type="email"],
input[type="tel"] {
	width: 100%;
	height: 61px;
	background-color: transparent;
}

textarea {
	border-bottom: 1px solid $color-body;
	width: 100%;
	background-color: transparent;
}

.inline-form {
	margin-bottom: 1.5em;
	input {
		vertical-align: middle;
		margin: 0;
	}
}

.block-form {
	input,
	textarea,
	.btn {
		display: block;
		width: 100%;
	}
}

.btn-login {
	@include fontscale(12px, 15px, 18px);
  display: inline-block;
  padding: 10px 15px;
  border: 2px solid $color-accent-contrast;
	background-color: #000;
  @include s-m {
		padding: 0;
		width: 200px;
		height: 45px;
		@include flex-center-all;
  }
  &:hover {
    color: #000;
    border: 2px solid $leap-green;
    background-color: $leap-green;
  }
}

.btn-sub {
	@include transition(all, 0.35s);
	@include fontscale(12px, 15px, 18px);
	font-family: 'Graphik-Semibold';
  display: inline-block !important;
	padding: 10px 30px;
	color: $color-accent-contrast;
	border: 2px solid $color-accent-contrast;
	width: auto !important;
	@include s-m {
		color: $color-grey;
		border: 2px solid $color-grey;
		padding: 0;
		width: 200px !important;
		height: 45px;
	}
	&:hover {
    color: #000;
    border: 2px solid $leap-green;
		background-color: $leap-green;
  }
}


button {
  border: 0;
  padding: 0;
  outline: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  border-radius: 0;
}

select::-ms-expand {
  display: none; /* hide the default arrow in ie10 and ie11 */
}

input {
  border: 0;
}
