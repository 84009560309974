/*** Global ***/

// Central parts of the site. Modules that are on every page (or almost every page)

/*** Header ***/
header {
  background: #fff;
  position: absolute;
  top: 0;
  height: 75px;
  width: 100%;
  z-index: 100;
}

.scroll-header {
  transform: translateY(-100%);
  background: #fff;
  position: fixed;
  top: 0;
  height: 75px;
  width: 100%;
  z-index: 30;

}

.headroom {

    will-change: transform;
    transition: transform 200ms ease;
}
.headroom--pinned {
    transform: translateY(0%);
}
.headroom--unpinned {
    transform: translateY(-100%);
}

.headroom--top {
    transform: translateY(-100%);
}

.headroom--not-top {
  box-shadow: -2px -3px 14px #00000057;
}



.home,
.page-apply,
.single-case_study {
  header {
    background: transparent;
  }
}

.header-main,
.header-second {
  height: 100%;
  font-family: $font-display;
  font-weight: 700;
  padding-left: 25px;
  padding-right: 25px;
  @include s-m{
    padding-left: 60px;
    padding-right: 60px;
  }

}

.brand-logo {

  position: relative;
  img, svg {
    width: 100%;
    height: auto;
    fill: $leap-green;
  }
  &--leap {
    width: 160px;
    display: block;
    @include med{
      width: 240px;
    }
  }
  &--hf {
    display: block;
    margin: 9px auto 0;
    width: 180px;
    @include med{
      width: 340px;
    }
  }
}

.home,
.page-apply,
.single-case_study {
  .brand-logo {
    svg {
      fill: #fff;
    }
  }
  .header-second {
    .brand-logo {
      svg {
        fill: $leap-green;
      }
    }
  }
}
/*** Main ***/
.main {

  &--404 {
    // height: calc(100vh - 75px);
  }
  &--demo {
    background-color: $color-black;
  }
}

.btn--header{
	@extend %btn-base;
	padding: 0 20px;
	line-height: 42px;
	height: 44px;
	display: none;
	@include s-m {
		display: inline-block;
	}
}

/*** Footer ***/
footer {

  background-color: $dark-green;
    .brand-logo{
      list-style: none;
      width: 241px;
      font-size: 13px;
      color: #fff !important;

      svg {
        fill: #fff !important;
      }
    }
  .brand-logo--hf-fr{
    width: 275px;
  }
  .sitemap {
    width: 100%;
    order: 1;
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
    @include s-m {
      justify-content: flex-end;
      width: 70%;
      margin: 0;
    }

    li {
      padding: 0;
      width: 100%;
      margin: 0;
      list-style: none;
      line-height: 2em;
      @include s-m {
        width: auto;
        margin-right: 10px;
      }
      &:last-child {
        @include s-m {
          margin-left: 10px;
        }
      }
    }

    a {
      padding: 10px 0;

      @include fontscale(12px, 14px, 16px);
      @include s-m {
        padding: 10px 10px;
        margin: 0;
        // margin-right: 10px;
      }
      @include med {
        padding: 10px 15px;
      }
      &:hover {
        color: $jade;
      }
    }
    a.btn{
      padding: 0 2em;
      margin-top: 2em;
      @include s-m {
        margin-top: 0;
        // margin-right: 0;
        // margin-left: 10px;
      }
      @include med {
        // margin-left: 15px;
      }
    }
  }
}


/*** Images ***/

.has-full-image {
  position: relative;
  .bg-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1000;
    width: 100%;
    height: 100%;
    background-color: $leap-green;
  }
}

.full-image-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  > img {
    @include bg-image;
  }
  &.align--left {
    > img {
      -webkit-object-position: left;
      -moz-object-position: left;
      -ms-object-position: left;
      -o-object-position: left;
      object-position: left;
    }
  }
  &.m-align--left {
    > img {
      @include med {
        -webkit-object-position: left;
        -moz-object-position: left;
        -ms-object-position: left;
        -o-object-position: left;
        object-position: left;
      }
    }
  }
  &.align--right {
    > img {
      -webkit-object-position: right;
      -moz-object-position: right;
      -ms-object-position: right;
      -o-object-position: right;
      object-position: right;
    }
  }
  &.m-align--right {
    > img {
      @include med {
        -webkit-object-position: right;
        -moz-object-position: right;
        -ms-object-position: right;
        -o-object-position: right;
        object-position: right;
      }
    }
  }
}

.custom-object-fit {
  background-size: cover;
  background-position: center center;
  > img {
    opacity: 0;
  }
  &.align--left {
    background-position: left center;
  }
  &.m-align--left {
    @include med {
      background-position: left center;
    }
  }
  &.align--right {
    background-position: right center;
  }
  &.m-align--right {
    @include med {
      background-position: right center;
    }
  }
}
