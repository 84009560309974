/*** Type Sizing calculations ***/

@function remcalc($size) {
  $remSize: $size / $main-font-size;
  @return $remSize * 1rem;
}

@mixin fontrem($size) {
  font-size: $size;
  font-size: remcalc($size);
}

@mixin rems($unit, $num) {
	#{$unit}: $num;
 	#{$unit}: remcalc($num);
}

@mixin fontscale($s:16px, $m:18px, $l:20px) {
	@include fontrem($s);
	@include med {
		@include fontrem($m);
	}
	@include large {
		@include fontrem($l);
	}
}


@mixin bodylink {
  color: $color-body;
  &:hover,
  &:focus{
    color: $leap-green;
  }
}

/*** Transitions ***/

@mixin transition($arg: all, $time:0.15s, $curve: $animation-curve-default) {
	transition: $time $arg $curve;
}

/*** Shadows ***/

@mixin shadow-2dp() {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              0 3px 1px -2px rgba(0, 0, 0, $shadow-key-umbra-opacity),
              0 1px 5px 0 rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}

@mixin shadow-4dp() {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              0 1px 10px 0 rgba(0, 0, 0, $shadow-ambient-shadow-opacity),
              0 2px 4px -1px rgba(0, 0, 0, $shadow-key-umbra-opacity);
}


/*** Reverse ***/

@mixin reverse {
	color: #ffffff;
	background-color: #000000; 
}
