/*** Grid System ***/

.container {
  max-width: 1600px;
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  @include s-m {
    padding-left: 60px;
    padding-right: 60px;
  }
  @include med {
    padding-left: 120px;
    padding-right: 120px;
  }
  &--narrow {
    @include med {
      padding-left: 200px;
      padding-right: 200px;
    }
    @include large {
      padding-left: 250px;
      padding-right: 250px;
    }
  }
  &--mobile-full {
    padding-left: 0;
    padding-right: 0;
    @include s-m {
      padding-left: 60px;
      padding-right: 60px;
    }
    @include med {
      padding-left: 120px;
      padding-right: 120px;
    }
  }
  &--desktop-full {
    padding-left: 25px;
    padding-right: 25px;
    @include s-m {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.full {
  margin-left: -25px;
  margin-right: -25px;
  @include sm {
    margin-left: 0;
    margin-right: 0;
  }
}

.clear,
%clear {
  zoom: 1;
  &:after {
    display: table;
    content: '';
    display: block;
    clear: both;
  }
}

.row,
%row {
  @include flex;
  flex-direction: column;
  @include med {
    flex-direction: row;
  }
}

.column,
%column {
  @include flex;
  flex-direction: column;
}

.grid,
%grid {
  @include flex;
  position: relative;
  flex-grow: 1;
  flex-wrap: wrap;
  @include sm {
    flex-direction: row;
  }
  // > .unit {
  //   padding-top: 0;
  // }
  > .gutter-l {
    @include med {
      padding-left: 20px;
    }
    @include large {
      padding-left: 30px;
    }
  }
  > .gutter-r {
    @include med {
      padding-right: 20px;
    }
    @include large {
      padding-right: 30px;
    }
  }
}

.grid--justify {
  justify-content: space-between;
}

.grid--center,
%grid--center {
  justify-content: center;
}

.s-m-grid--center {
  @include s-m {
    justify-content: center;
  }
}

.grid--v-center {
  align-items: center;
}

.grid--v-top{
  align-items: flex-start;
}

.grid--v-bottom {
  align-items: flex-end;
}

.grid--gutters,
%gutters {
  margin: 0 -16px 0 -16px;
  @include med {
    margin: 0 -20px 0 -20px;
  }
  @include large {
    margin: 0 -30px 0 -30px;
  }
  > .unit {
    @include gutter-pad;
  }
}



.end {
  justify-content: flex-end;
}

.s-m-end {
  @include s-m {
    justify-content: flex-end;
  }
}

.m-end {
  @include med {
    justify-content: flex-end;
  }
}

.s-m-grid--right {
  @include s-m {
    display: flex;
    justify-content: flex-end;
  }
}

.row--reverse {
  flex-direction: column-reverse;
  @include med {
    flex-direction: row-reverse;
  }
}

.column--reverse {
  flex-direction: column-reverse;
  @include med {
    flex-direction: row;
  }
}

.gutters,
%gutters {
  margin: 0 -16px 0 -16px;
  @include med {
    margin: 0 -20px 0 -20px;
  }
  @include large {
    margin: 0 -30px 0 -30px;
  }
  > .unit {
    @include gutter-pad;
  }
}

.gutters-tight {
  margin: 0 -10px 0 -10px;
  @include med {
    margin: 0 -15px 0 -15px;
  }
  @include large {
    margin: 0 -20px 0 -20px;
  }
  > .unit {
    @include gutter-pad-tight;
  }
}

.gutters-skinny {
  margin: 0 -5px 0 -5px;
  @include med {
    margin: 0 -10px 0 -10px;
  }
  > .unit {
    @include gutter-pad-skinny;
  }
}

.gutters-wide,
%gutters-wide {
  margin: 0 -24px 0 -24px;
  @include med {
    margin: 0 -36px 0 -36px;
  }
  @include large {
    margin: 0 -48px 0 -48px;
  }
  > .unit {
    @include gutter-pad-wide;
  }
}

.gallery-gap {
  margin: -10px;
  @include med {
    margin: -15px;
  }
  @include large {
    margin: -20px;
  }
  img {
    @include gutter-pad-tight;
    width: 100%;
  }
}

/*** Grid wrap modifiers ***/

.no-wrap {
  flex-wrap: nowrap;
}

.wrap-2 {
  align-items: stretch;
  > *,
  .unit {
    width: 50%;
  }
}

.wrap-3 {
  > *,
  .unit {
    width: 33.3333%;
  }
}

.wrap-4 {
  > *,
  .unit {
    width: 25%;
  }
}

.sm-wrap-2 {
  align-items: stretch;
  >*,
  .unit {
    @include sm {
      width: 50%;
    }
  }
}

.sm-wrap-3 {
  .unit {
    @include sm {
      width: 33.333%;
    }
  }
}

.sm-wrap-4 {
  .unit {
    @include sm {
      width: 25%;
    }
  }
}

.s-m-no-wrap {
  @include s-m {
    flex-wrap: nowrap;
  }
}

.s-m-wrap-2 {
  align-items: stretch;
  >*,
  .unit {
    @include s-m {
      width: 50%;
    }
  }
}

.s-m-wrap-3 {
  .unit {
    @include s-m {
      width: 33.333%;
    }
  }
}

.s-m-wrap-4 {
  .unit {
    @include s-m {
      width: 25%;
    }
  }
}

.s-m-wrap-5 {
  .unit {
    @include s-m {
      width: 20%;
    }
  }
}

.s-m-wrap-6 {
  .unit {
    @include s-m {
      width: 16.6666%;
    }
  }
}


.m-no-wrap {
  @include med {
    flex-wrap: nowrap;
  }
}

.m-wrap-2 {
  @include med {
    > .unit {
      width: 50%;
    }
  }
}

.m-wrap-3 {
  .unit {
    @include med {
      width: 33.3333%;
    }
  }
}

.m-wrap-4 {
  .unit {
    @include med {
      width: 25%;
    }
  }
}

.m-wrap-5 {
  .unit {
    @include med {
      width: 20%;
    }
  }
}

.m-wrap-6 {
  .unit {
    @include med {
      width: calc(100% / 6);
    }
  }
}

.m-wrap-7 {
  .unit {
    @include med {
      width: calc(100% / 7);
    }
  }
}

/*** Unit styles ***/
.unit,
%unit {
  width: 100%;
}

.pad,
%pad {
  @include pad;
}

.half {
  width: 50%;
}

.grow {
  @include med {
    width: auto !important;
    flex-grow: inherit;
  }
}

.no-grow {
  flex-grow: 0;
}

@include large {
  .l-half {
    width: 50%;
  }
}

.a-1-3 {
  width: 33.3333%;
}

.a-2-3 {
  width: 66.6666%;
}

.a-2-5 {
  width: 40%;
}

.a-3-5 {
  width: 60%;
}

.a-85 {
  width: 85%;
}

@include sm {
  .sm-half {
    width: 50%;
  }
  .sm-1-3 {
    width: 33.3333%;
  }
  .sm-2-3 {
    width: 66.6666%;
  }
  .sm-3-4 {
    width: 75%;
  }
  .sm-2-5 {
    width: 40%;
  }
  .sm-3-5 {
    width: 60%;
  }
  .sm-85 {
    width: 85%;
  }
}

@include s-m {
  .s-half {
    width: 50%;
  }
  .s-half-gutter {
    width: 57%;
  }
  .s-2-5 {
    width: 40%;
  }
  .s-1-3 {
    width: 33.3333%;
  }
  .s-2-3 {
    width: 66.6666%;
  }
  .s-3-4 {
    width: 75%;
  }
  .s-1-4 {
    width: 25%;
  }
  .s-1-5 {
    width: 20%;
  }
  .s-4-5 {
    width: 80%;
  }
  .s-3-5 {
    width: 60%;
  }
  .s-70 {
    width: 70%;
  }
  .s-30 {
    width: 30%;
  }
  .s-15 {
    width: 15%;
  }
  .s-85 {
    width: 85%;
  }
  .s-90 {
    width: 90%;
  }
  .s-5 {
    width: 41.6666%;
  }
  .s-7 {
    width: 58.3333%;
  }
}

@include med {
  .m-half {
    width: 50%;
  }
  .m-half-gutter {
    width: 57%;
  }
  .m-1-3,
  %m-1-3 {
    width: 33.3333%;
  }
  .m-1-4,
  %m-1-4 {
    width: 25%;
  }
  .m-1-5,
  %m-1-5 {
    width: 20%;
  }
  .m-2-3 {
    width: 66.6666%;
  }
  .m-half-pl {
    width: 54%
  }
  .m-half-mn{
    width: 46%
  }
  .m-5,
  %m-5 {
    width: 41.6666%;
  }
  .m-7 {
    width: 58.3333%;
  }
  .m-30 {
    width: 30%;
  }
  .m-2-5 {
    width: 40%;
  }
  .m-48 {
    width: 48%;
  }
  .m-3-5 {
    width: 60%;
  }
  .m-70 {
    width: 70%;
  }
  .m-3-4 {
    width: 75%;
  }
  .m-4-5 {
    width: 80%;
  }
  .m-85 {
    width: 85%;
  }
  .m-95 {
    width: 95%;
  }
  .offset-1 {
    margin-left: ($col);
  }
  .offset-2 {
    margin-left: ($col * 2);
  }
  .offset-3 {
    margin-left: ($col * 3);
  }
  .offset-4 {
    margin-left: ($col * 4);
  }
  .offset-half {
    margin-left: 50%;
  }
}

.h-100 {
  height: 100%;
}

/*** Spacing ***/

.section,
%section {
  padding-top: 5em;
  padding-bottom: 1em;
}

.l-space-1 {
  margin-bottom: 1em;
}

.l-space-2 {
  margin-bottom: 2em;
}

.l-space-3 {
  margin-bottom: 3em;
}

.l-space-4 {
  margin-bottom: 4em;
}

.s-m-l-space-0 {
  @include s-m {
    margin-bottom: 0;
  }
}

.s-m-l-space-1 {
  @include s-m {
    margin-bottom: 1em;
  }
}

.s-m-l-space-4 {
  @include s-m {
    margin-bottom: 4em;
  }
}

.m-l-space-4 {
  @include med {
    margin-bottom: 4em;
  }
}

.t-space-1 {
  padding-top: 1em;
}

.t-space-2 {
  padding-top: 2em;
}

.t-space-3 {
  padding-top: 3em;
}

.t-space-4 {
  padding-top: 4em;
}

.t-space-20 {
  padding-top: 20em;
}

.s-m-t-space-0 {
  @include s-m {
    padding-top: 0;
  }
}

.s-m-t-space-1 {
  @include s-m {
    padding-top: 1em;
  }
}

.s-m-t-space-2 {
  @include s-m {
    padding-top: 2em;
  }
}

.s-m-t-space-4 {
  @include s-m {
    padding-top: 4em;
  }
}

.s-m-t-space-6 {
  @include s-m {
    padding-top: 6em;
  }
}

.s-m-t-space-8 {
  @include s-m {
    padding-top: 8em;
  }
}

.s-m-t-space-10 {
  @include s-m {
    padding-top: 10em;
  }
}

.b-space-1 {
  padding-bottom: 1em;
}

.b-space-2 {
  padding-bottom: 2em;
}

.b-space-3 {
  padding-bottom: 3em;
}

.b-space-4 {
  padding-bottom: 4em;
}

.b-space-5 {
  padding-bottom: 5em;
}

.b-space-6 {
  padding-bottom: 6em;
}

.b-space-8 {
  padding-bottom: 8em;
}

.s-m-b-space-4 {
  @include s-m {
    padding-bottom: 4em;
  }
}

.s-m-b-space-6 {
  @include s-m {
    padding-bottom: 6em;
  }
}

.s-m-b-space-8 {
  @include s-m {
    padding-bottom: 8em;
  }
}

.s-m-b-space-10 {
  @include s-m {
    padding-bottom: 10em;
  }
}

.m-b-space-7 {
  @include med {
    padding-bottom: 7em;
  }
}

.v-space-1 {
  padding-top: 1em;
  padding-bottom: 1em;
}

.v-space-2 {
  padding-top: 2em;
  padding-bottom: 2em;
}

.v-space-3 {
  padding-top: 3em;
  padding-bottom: 3em;
}

.v-space-4 {
  padding-top: 4em;
  padding-bottom: 4em;
}

.v-space-6 {
  padding-top: 6em;
  padding-bottom: 6em;
}

.v-space-8 {
  padding-top: 8em;
  padding-bottom: 8em;
}

.v-space-10 {
  padding-top: 10em;
  padding-bottom: 10em;
}

.s-m-v-space-0 {
  @include s-m {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.s-m-v-space-2 {
  @include s-m {
    padding-top: 2em;
    padding-bottom: 2em;
  }
}

.s-m-v-space-3 {
  @include s-m {
    padding-top: 3em;
    padding-bottom: 3em;
  }
}

.s-m-v-space-4 {
  @include s-m {
    padding-top: 4em;
    padding-bottom: 4em;
  }
}

.s-m-v-space-6 {
  @include s-m {
    padding-top: 6em;
    padding-bottom: 6em;
  }
}

.s-m-v-space-8 {
  @include s-m {
    padding-top: 8em;
    padding-bottom: 8em;
  }
}

.s-m-v-space-10 {
  @include s-m {
    padding-top: 10em;
    padding-bottom: 10em;
  }
}

.m-v-space-0 {
  @include med {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.m-v-space-2 {
  @include med {
    padding-top: 2em;
    padding-bottom: 2em;
  }
}

.m-v-space-3 {
  @include med {
    padding-top: 3em;
    padding-bottom: 3em;
  }
}

.m-v-space-4 {
  @include med {
    padding-top: 4em;
    padding-bottom: 4em;
  }
}

.m-v-space-6 {
  @include med {
    padding-top: 6em;
    padding-bottom: 6em;
  }
}

.m-v-space-8 {
  @include med {
    padding-top: 8em;
    padding-bottom: 8em;
  }
}

.l-v-space-6 {
  @include large {
    padding-top: 6em;
    padding-bottom: 6em;
  }
}

.l-v-space-10 {
  @include large {
    padding-top: 10em;
    padding-bottom: 10em;
  }
}

.l-v-space-14 {
  @include large {
    padding-top: 14em;
    padding-bottom: 14em;
  }
}

.no-v-pad {
  padding-top: 0;
  padding-bottom: 0;
}

.v-m-2{
  margin-top: 2em;
  margin-bottom: 2em;
}

/*** Flexbox ***/

.no-flexbox {
  .grid,
  .row {
    width: 100%;
    zoom: 1;
    overflow: hidden;
    margin: auto;
  }
  .grid:after,
  .row:after {
    display: table;
    content: '';
    display: block;
    clear: both;
  }
  .unit {
    float: left;
  }
  .m-half {
    width: 50%;
  }
}

// center things vertically
.v-center,
%v-center {
  align-items: center;
}

.sm-v-center {
  @include sm {
    align-items: center;
  }
}

.s-m-v-center {
  @include s-m {
    align-items: center;
  }
}

.m-v-center {
  @include med {
    align-items: center;
  }
}

// full width for v center if needed
.v-center__content {
  width: 100%;
}

// center horizontally
.h-center,
%h-center {
  justify-content: center;
}

.s-m-h-center,
%s-m-h-center {
  @include s-m {
    @include flex;
    justify-content: center;
  }
}

.m-h-center,
%m-h-center {
  @include med {
    @include flex;
    justify-content: center;
  }
}

.a-center {
  margin: 0 auto;
}

// center both
.center,
%center {
  @extend %v-center;
  @extend %h-center;
}

.m-center {
  @include med {
    @include flex;
    justify-content: center;
    align-items: center;
  }
}

// table fallback
.no-flexbox {
  .v-center__content {
    display: table-cell;
    vertical-align: middle;
  }
}

/*** Flex Modifier ***/

.flex-0 {
  flex: 0;
}

.flex-0-0-auto {
  -webkit-flex: 0 0 auto; /* Safari 6.1+ */
  -ms-flex: 0 0 auto; /* IE 10 */
  flex: 0 0 auto;
}

.flex-1 {
  flex: 1;
}

.s-m-flex-0-auto {
  @include s-m {
    flex: 0;
  }
  @include med {
    flex: auto;
  }
}

.s-m-flex-0 {
  @include s-m {
    -webkit-flex: 0 0 auto; /* Safari 6.1+ */
    -ms-flex: 0 0 auto; /* IE 10 */
    flex: 0 0 auto;
  }
}

.s-m-flex-1 {
  @include s-m {
    -webkit-flex: 1 1 100%; /* Safari 6.1+ */
    -ms-flex: 1 1 100%; /* IE 10 */
    flex: 1 1 100%;
  }
}

.s-m-flex-2 {
  @include s-m {
    flex: 2;
  }
}

.s-m-flex-3 {
  @include s-m {
    flex: 3;
  }
}

.m-flex-1 {
  @include med {
    flex: 1;
  }
}

.m-flex-2 {
  @include med {
    flex: 2;
  }
}

.m-flex-3 {
  @include med {
    flex: 3;
  }
}

/*** Columns ***/

.s-m-cols-2 {
  @include s-m {
    column-count: 2;
    column-gap: 48px;
  }
}

.s-m-cols-2-0 {
  @include s-m {
    column-count: 2;
    column-gap: 0;
  }
}

.m-cols-2 {
  @include med {
    column-count: 2;
    column-gap: 48px;
  }
}

.m-cols-3 {
  @include med {
    column-count: 3;
    column-gap: 48px;
  }
}

.m-cols-3-0 {
  @include med {
    column-count: 3;
    column-gap: 0;
  }
}

/*** Gutenberg Wide and Full Width Alignment ***/

.entry-content {
  position: relative;
}

.entry-content .alignwide {
  @include s-m {
    margin-left: calc((-100vw / 2 + 100% / 2) + 120px);
    margin-right: calc((-100vw / 2 + 100% / 2) + 120px);
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.entry-content .wp-block-embed.alignwide,
.entry-content .wp-block-gallery.alignwide,
.entry-content .wp-block-blockgallery-carousel.alignwide,
.entry-content .wp-block-blockgallery-masonry.alignwide,
.entry-content .wp-block-blockgallery-stacked.alignwide,
.entry-content .wp-block-table.alignwide {
  @include s-m {
    display: block;
  }
}

.entry-content .wp-block-media-text.alignwide {
  display: flex;
  flex-direction: column;
  @include s-m {
    display: grid;
  }
}

.entry-content .wp-block-columns.alignwide {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.entry-content .alignwide img {
  @include s-m {
    max-width: calc(1600px - 240px);
  }
}

.entry-content .wp-block-columns.alignwide img {
  @include s-m {
    max-width: 100%;
  }
}

.entry-content .alignwide figcaption {
  width: 100%;
  @include s-m {
    max-width: calc(1600px - 240px);
  }
}

.entry-content .alignfull {
  margin-left: calc(-100vw / 2 + 100% / 2);
  margin-right: calc(-100vw / 2 + 100% / 2);
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.entry-content .wp-block-embed.alignfull,
.entry-content .wp-block-gallery.alignfull,
.entry-content .wp-block-blockgallery-carousel.alignfull,
.entry-content .wp-block-blockgallery-masonry.alignfull,
.entry-content .wp-block-blockgallery-stacked.alignfull,
.entry-content .wp-block-table.alignfull {
  display: block;
}

.entry-content .wp-block-media-text.alignfull {
  display: flex;
  @include s-m {
    display: grid;
  }
}

.entry-content .wp-block-columns.alignfull {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 25px;
  padding-right: 25px;
  @include s-m {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.entry-content .alignfull img  {
  max-width: 1600px;
}

.entry-content .wp-block-columns.alignfull img {
  max-width: 100%;
}

.entry-content .alignfull figcaption {
  padding-left: 25px;
  padding-right: 25px;
  max-width: 1600px;
  width: 100%;
  @include sm {
    padding-left: 0;
    padding-right: 0;
  }
}

.entry-content .wp-block-image {
  .is-resized {
    margin-top: 1em;
    margin-bottom: 1em;
    @include sm {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .alignright {
    @include sm {
      margin-left: 1em;
    }
  }
  .alignleft {
    @include sm {
      margin-right: 1em;
    }
  }
}

.entry-content .wp-block-blockgallery-carousel {
  height: auto;
}

.entry-content .wp-block-blockgallery-masonry.alignwide img,
.entry-content .wp-block-blockgallery-masonry.alignfull img {
  max-width: 100%;
}

.entry-content .wp-block-table {
  width: 100%;
  @include fontscale(12px, 14px, 16px);
  tr {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5em;
    @include s-m {
      display: table-row;
      margin-bottom: 0;
    }
  }
  td {
    border: 1px solid $color-grey;
    line-height: 1.8;
    padding: 15px;
    @include s-m {
      padding: 25px 75px 25px 25px
    }
    &:first-child {
      vertical-align: top;
    }
  }
}

.entry-content .wp-block-table.alignwide,
.entry-content .wp-block-table.alignfull {
  width: auto;
}

.entry-content .wp-block-table.alignfull {
  padding-left: 25px;
  padding-right: 25px;
  @include sm {
    padding-left: 0;
    padding-right: 0;
  }
}


.pad-row {
  @include flex;
  margin-left: -20px;
  margin-right: -20px;
  >* {
    margin: 20px;
  }
}
