/**********

LAYOUT

**********/


/*** Size Media Queries ***/

@mixin h-xs {
	@media(min-height:$xs) {
		@content;
	}
}

@mixin x-sm {
  @media(min-width:$x-sm) {
    @content;
  }
}
@mixin sm {
  @media(min-width:$sm) {
    @content;
  }
}
@mixin s-m {
  @media(min-width:$s-m) {
    @content;
  }
}

@mixin med {
  @media(min-width:$med) {
    @content;
  }
}

$max-med: ($med - 1);
$max-sm: ($sm - 1);


@mixin max-sm {
	@media(max-width:$max-sm) {
		@content;
	}
}

@mixin max-med {
  @media(max-width: $max-med) {
    @content;
  }
}

@mixin m-l {
  @media (min-width: $m-l) {
    @content;
  }
}

@mixin large {
  @media(min-width:$large) {
    @content;
  }
}

@mixin larger {
  @media(min-width:$larger) {
    @content;
  }
}

@mixin x-large {
  @media(min-width:$x-large) {
    @content;
  }
}

/*** Hi DPI media query ***/

@mixin hidpi {
	@media
	only screen and (min-width:$med) and (-webkit-min-device-pixel-ratio: 2),
	only screen and (min-width:$med) and (   min--moz-device-pixel-ratio: 2),
	only screen and (min-width:$med) and (     -o-min-device-pixel-ratio: 2/1),
	only screen and (min-width:$med) and (        min-device-pixel-ratio: 2),
	only screen and (min-width:$med) and (                min-resolution: 192dpi),
	only screen and (min-width:$med) and (                min-resolution: 2dppx) {

	  @content;

	}
}



/*** Padding ***/

@mixin pad {
  padding: 30px;
}

@mixin gutter-pad {
  padding: 16px;
  @include med {
    padding: 20px;
  }
  @include large {
    padding: 30px;
  }
}

@mixin gutter-pad-tight {
  padding: 10px;
  @include med {
    padding: 15px;
  }
  @include large {
    padding: 20px;
  }
}

@mixin gutter-pad-skinny {
  padding: 5px;
  @include med {
    padding: 10px;
  }
}

@mixin gutter-pad-wide {
  padding: 24px;
  @include med {
    padding: 36px;
  }
  @include large {
    padding: 48px;
  }
}

@mixin card-gutter-pad-left($s-m: 20px, $med: 30px, $large: 40px) {
  @include s-m {
    padding-left: $s-m;
  }
  @include med {
    padding-left: $med;
  }
  @include large {
    padding-left: $large;
  }
}

@mixin card-gutter-pad-left-only($s-m: 40px, $med: 60px, $large: 80px) {
  @include s-m {
    padding-left: $s-m;
  }
  @include med {
    padding-left: $med;
  }
  @include large {
    padding-left: $large;
  }
}

@mixin card-gutter-pad-right($s-m: 20px, $med: 30px, $large: 40px) {
  @include s-m {
    padding-right: $s-m;
  }
  @include med {
    padding-right: $med;
  }
  @include large {
    padding-right: $large;
  }
}

@mixin card-gutter-pad-right-only($s-m: 40px, $med: 60px, $large: 80px) {
  @include s-m {
    padding-right: $s-m;
  }
  @include med {
    padding-right: $med;
  }
  @include large {
    padding-right: $large;
  }
}

@mixin pad-mobile {
  padding-left: 25px;
  padding-right: 25px;
  @include s-m {
    padding-left: 0;
    padding-right: 0;
  }
}


/*** Flexbox ***/

@mixin flex {
	display: table;
	display: flex;
}

@mixin flex-unit($unit:1) {
	display: table-cell;
	flex: $unit;
}

@mixin flex-center-all {
	display: flex;
	justify-content: center;
	align-items: center;
}


/*** Positioning ***/

@mixin position-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}

@mixin position($type, $top, $right, $bottom, $left) {
	position: $type;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}

@mixin fixed-placeholder($position, $height, $maxheight) {
	position: $position;
  height: $height;
  max-height: $maxheight;
}

/*** Size ***/

@mixin size($height, $width) {
	height: $height;
	width: $width;
}

/*** Spacing ***/

@mixin mgnbtm($s:16px, $m:18px, $l:20px) {
	margin-bottom: $s;
	@include med {
		margin-bottom: $m;
	}
	@include large {
		margin-bottom: $l;
	}
}

/***********

IMAGES

***********/




/*** Media query background images ***/

@mixin image-s-l($image) {
	background-image: url(#{$image}-s.jpg);
	@include med {
		background-image: url(#{$image}-m.jpg);
	}
	@include large {
		background-image: url(#{$image}-l.jpg);
	}
	@include hidpi {
		background-image: url(#{$image}-2x.jpg);
	}
	background-size: cover;
	background-position: center;
}

@mixin image-xs-l($image) {
	background-image: url(#{$image}-xs.jpg);
	@include sm {
		background-image: url(#{$image}-s.jpg);
	}
	@include med {
		background-image: url(#{$image}-m.jpg);
	}
	@include large {
		background-image: url(#{$image}-l.jpg);
	}
	@include hidpi {
		background-image: url(#{$image}-2x.jpg);
	}
	background-size: cover;
	background-position: center;
}

@mixin image-m-l($image) {
	@include med {
		background-image: url(#{$image}-l.jpg);
	}
	@include hidpi {
		background-image: url(#{$image}-2x.jpg);
	}
	background-size: cover;
	background-position: center;
}



/*** Background ***/

@mixin bg-center-cover {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

@mixin bg-hero-fixed($paddingtop, $height, $maxheight) {
	position: fixed;
  padding-top: $paddingtop;
  height: $height;
  max-height: $maxheight;
  display: flex;
  flex-direction: column;
	z-index: -1;
	@include large {
		left: 50%;
		transform: translate(-50%, 0);
	}
}

@mixin bg-hero($height, $maxheight) {
  height: $height;
  max-height: $maxheight;
  display: flex;
  flex-direction: column;
}

@mixin bg-image {
  position: absolute;
  top: 0;
  left: 0;
  // z-index: -999;
  -webkit-object-fit: cover;
  -moz-object-fit: cover;
  -ms-object-fit: cover;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}