/*** Global ***/

.no-js {
  .animate,
  .js-animate {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}

*, *:before, *:after {
	box-sizing: border-box;
}

// html, body {
//   height: 100%;
// }

body {
  position: relative;
  // display: flex;
  // flex-direction: column;
}

header, footer {
  // flex-shrink: 0;
}

img, video {
	max-width: 100%;
	display: block;
}

figure {
  margin: 0;
  padding: 0;
  > .ab {
    @include s-m {
      position: absolute;
    }
  }
}

ul, ol {
  padding: 0;
  padding-inline-start: 0;
  list-style-position: outside;
  margin-top: 0;
  margin-left: 1em;
}

ol li {
  margin-bottom: 0.5em;
}

:focus {
  outline: 1px solid lighten($jade, 20%);
  transition: none;
}
