
// Global Menu

.nav--main {
  display: block;
  margin: 0;
  list-style: none;
  width: 100%;
  height: 100vh;
  padding: 70px 20px 20px;
  background-color: $color-dark-grey;
  position: fixed;
  top: 0;
  right: -100%;
  transition:  right 0.2s ease-in-out;
  width: 100%;
  z-index: 20;
  .menu-item{
    margin: 1.5em 0;
  }
  .menu-item-apply{
    margin-top: 2em;
  }
  .current-menu-link,
  .current-menu-item > a,
  .current-page-ancestor > a,
  .current_page_parent > a {
    color: #fff;
  }
}

.nav-open {
  .nav--main {
    display: block;
    right: 0;
    box-shadow: -2px -3px 14px #00000057;
  }
}

.btn--menu{
  padding: 0 !important
}


@include s-m {
  .nav--main {
    width: 400px;
    right:-400px;
  }
}

@include max-med {
  .menu-opener {
    font-size: 10px;
    margin: 0;
    padding: 0 2px;
  }
  .brand-logo--hf,
  .brand-logo--leap {
    width: 120px;
    margin: 0;
  }
  .btn--header {
    display: none!important;
  }
}


.nav-wrap{
  @include flex;
  justify-content: flex-end;

}

.nav-wrap,
.leap-wrap {
  flex: 1;
}

.hf-wrap{
  flex: 2;
}

.menu-item {
  @include fontscale(16px, 16px, 16px);
  line-height: normal;
  margin: 0 18px;

  a {
    @include fontrem(22px);
    padding: 12px 28px;
    display: inline-block;
    width: 100%;
    padding: 8px 15px;
    color: #ffffff;
    margin: 0;
    position: relative;
    &:hover {
      color: $orange;
    }
  }


  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}


.menu-open{
  font-size: 19px;
  padding: 0 20px;
  margin: 0 20px;
  line-height: 44px;
}

// Nav Icon
.menu-toggle {
  width: 44px;
  height: 44px;
  position: absolute;
  right: 15px;
  top: 15px;
}


.menu-opener{
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  padding: 0 8px;
  color: $leap-green;
  line-height: 44px;
  @include s-m {
    margin: 0 20px;
    padding: 0 20px;
    // font-size: 19px;
  }
}

.scroll-header{
  .menu-opener {
    color: $leap-green!important;
  }
}

.home,
.page-apply,
.single-case_study {
  .menu-opener {
    color: #fff;
  }
}

%navicon {
  display: inline-block;
  width: 100%;
  height: 2px;
  background-color: #fff;
  color: transparent;
  user-select: none;
  border-radius: 2px;
  left: 0;
}

.navicon {
  @extend %navicon;
  color: transparent;
  background: transparent;
  transition: transform 0.2s ease;
  transition: .3s;
  position: relative;
  top: 3px;
  &:before,
  &:after {
    transition: transform 0.2s ease;
    top: 0;
    @extend %navicon;
    position: absolute;
    content: '';
    transform-origin: center center;
  }
  &:before {
    transform: rotate3d(0, 0, 1, 45deg);
    top: 0;
  }
  &:after {
    transform: rotate3d(0, 0, 1, -45deg);
    top: 0;
  }
}
