.no-js {
  .animate,
  .js-animate {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}

.js {
  .up-in {
  	will-change: transform;
    -webkit-backface-visibility: hidden;
    opacity: 0;
    transform: translate3d(0,40px,0) scale(0.98);
    transition: opacity 700ms $animation-curve-default,transform 800ms $animation-curve-default;
    &.is-active {
      opacity: 1;
      transform: translate3d(0,0,0);
    }
  }

  .up-in-long {
    @extend .up-in;
    transition: opacity 3000ms $animation-curve-default,transform 1200ms $animation-curve-default;
  }

  .up-in-far-long {
  	@extend .up-in;
    transform: translate3d(0,100px,0) scale(0.98);
    transition: opacity 3000ms $animation-curve-default,transform 1200ms $animation-curve-default;
  }

  .down-in {
  	will-change: transform;
    -webkit-backface-visibility: hidden;
    opacity: 0;
    transform: translate3d(0,-40px,0) scale(0.98);
    transition: opacity 700ms $animation-curve-slow-out-slow-in,transform 800ms $animation-curve-default;
    &.is-active {
      opacity: 1;
      transform: translate3d(0,0,0);
    }
  }

  .in {
    opacity: 0;
    transition: opacity 700ms $animation-curve-default;
    &.is-active {
      opacity: 1;
    }
  }

  .in-smooth {
    @extend .in;
    transition: opacity 1000ms $animation-curve-slow-out-slow-in;
  }

  .delay-1 {
    transition-delay: .7s;
  }

  .delay-2 {
    transition-delay: 1.5s;
  }

  .delay-3 {
    transition-delay: 1s;
  }

  .delay-4 {
    transition-delay: 2s;
  }

  .is-active {
    .in {
      opacity: 1;
    }
    .rev {
      background: $leap-green;
    }
    .up-in {
      opacity: 1;
      transform: translate3d(0,0,0) scale(1);
    }
  }
}